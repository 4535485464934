<template>
  <notifications position="top right">
    <template #body="props">
      <div class="notification" :class="getClass(props.item.type)">
        <SvgIcon
          :name="getIcon(props.item.type)"
          class="icon-md mr-3"
        />
        <div class="flex-1 px-3">
          <p v-if="props.item.title" class="font-bold">
            {{ props.item.title }}
          </p>
          <span v-if="props.item.text">
            {{ props.item.text }}
          </span>
          <slot></slot>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script setup>
const ERRORTYPE = 'error';
const WARNINGRTYPE = 'warning';

const getIcon = (type) => {
  if (type === ERRORTYPE) {
    return 'error-circle'
  }
  else if (type === WARNINGRTYPE) {
    return 'warning-circle'
  }
  else {
    return 'done-circle'
  }
}

const getClass = (type) => {
  if (type === ERRORTYPE) {
    return 'is-error'
  }
  else if (type === WARNINGRTYPE) {
    return 'is-warning'
  }
  else {
    return 'is-success'
  }
}
</script>
