<template>
  <RouterView />

  <CustomNotification />
</template>

<script setup>
import CustomNotification from '@/components/CustomNotification.vue';
import { setLanguage } from '@/services/language.service';

setLanguage('de');
</script>
