import axios from 'axios';
import router from '../router';


const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};


const loginInterceptor = (config) => {
  // As the api for live and the frontend for staging are both running on
  // subdomains, we need to postfix the cookie, so each subdomain takes the
  // proper cookie and we do not mix them up
  let postfix = '';
  if (`${import.meta.env.VITE_API_COOKIE_POSTFIX}` !== "undefined") {
    postfix = `${import.meta.env.VITE_API_COOKIE_POSTFIX}`;
  }
  config.headers['X-CSRFToken'] = getCookie('csrftoken' + postfix);
  return config;
};

const userApi = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
  withCredentials: true,
});

const userApiUnprotected = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}unprotected`,
  withCredentials: true,
});

userApi.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    }
    // Return any error which is not due to authentication back to the calling service
    if (
      (error.response && error.response.status !== 401) ||
      !router.app._route.meta.requiresAuth
    ) {
      return Promise.reject(error);
    }

    router.push({ name: 'login' });
  }
);

userApiUnprotected.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (
      (error.response && error.response.status !== 401) ||
      !router.app._route.meta.requiresAuth
    ) {
      return Promise.reject(error);
    }

    router.push({ name: 'login' });
  }
);

userApi.interceptors.request.use(loginInterceptor);

export { userApi, userApiUnprotected };
